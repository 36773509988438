<template>
  <div class="invitation-container">
    <div style="padding: 35px; padding-top: 15px;">
      <transition name="tour-guide-fade">
        <div
          v-if="showTitles"
          class="py-5 text-2xl font-weight-bold"
        >
          {{ $t('how_messages_look_to_you') }}
        </div>
      </transition>

      <transition
        v-if="$store.state.eventData.languages.length > 1"
        name="tour-guide-fade"
      >
        <div
          v-if="showTitles"
          class="text-xl"
        >
          {{ $t('edit_messages_in_all_languages') }}
        </div>
      </transition>
      <transition
        v-else
        name="tour-guide-fade"
      >
        <div
          v-if="showTitles"
          class="text-xl"
        >
          {{ $t('edit_details_in_message_template') }}
        </div>
      </transition>
    </div>
    <transition-group
      name="tour-guide-slide"
      tag="div"
      class="pt-0 message-container"
      style="padding:35px"
    >
      <div
        v-for="msg in displayedMessages"
        :key="msg.id"
        class="message-row"
      >
        <div class="message-header">
          <v-avatar
            size="30"
            class="v-avatar-light-bg primary--text"
          >
            <v-icon
              size="22"
              color="primary"
            >
              {{ messagesTypes.filter(el => el.type === msg.type)[0].icon }}
            </v-icon>
          </v-avatar>
          <div class="message-title mx-1">
            {{ getMessageTypeTitle(msg.type) }}:
          </div>
        </div>
        <div class="message-bubble">
          <div
            class="message-text"
            dir="auto"
            v-html="msg.message"
          ></div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiAlertCircleOutline, mdiHeartOutline, mdiMapMarker, mdiMessageTextOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useTourGuideTitleAnimation } from '../useTourGuideTitleAnimation'

export default {
  name: 'Message',
  props: {
    currentStep: {
      required: true,
    },
    stepIndex: {
      required: true,
    },
  },
  setup(props) {
    const messages = ref([])
    const displayedMessages = ref([])

    let animationInterval

    const displayMessagesSequentially = () => {
      let index = 0
      animationInterval = setInterval(() => {
        if (index < messages.value.length) {
          displayedMessages.value.push(messages.value[index])
          index += 1
        } else {
          clearInterval(animationInterval)
        }
      }, 200)
    }

    const { showTitles } = useTourGuideTitleAnimation(props, displayMessagesSequentially, () => {
      clearInterval(animationInterval)
      displayedMessages.value = []
    })

    const messagesTypes = [
      {
        type: 1,
        value: 'invitationMessage',
        icon: mdiMessageTextOutline,
      },
      {
        type: 2,
        value: 'direction',
        icon: mdiMapMarker,
      },
      {
        type: 3,
        value: 'thanks',
        icon: mdiHeartOutline,
      },
    ]

    const getMessages = () => {
      const linkRSVP = 'https://anita-lee.com/i/J41Sjh'
      const linkWaze = 'https://anita-lee.com/w/1415jh'

      store.dispatch('getMessageTemplate', { id: store.state.eventData.id }).then(response => {
        // Assuming the response data contains unique ids for each message
        messages.value = response.data
          .map((msg, index) => {
            let { message } = msg
            if (msg.type === 1) {
              message += `<br>${i18n.t('ForRsvp', store.state.eventData.defaultLang)}<br>${linkRSVP}`
            } else if (msg.type === 2) {
              message += `<br>${i18n.t('ForDirection', store.state.eventData.defaultLang)}<br>${linkWaze}`
            }

            return { ...msg, id: index, message }
          })
          .filter(el => el.lang === store.state.eventData.defaultLang)
      })
    }

    const getMessageTypeTitle = type => {
      switch (type) {
        case 1:
          return i18n.t('invitationMessage')
        case 2:
          return i18n.t('DirectionMessage')
        case 3:
          return i18n.t('ThanksMsg')
        default:
          return ''
      }
    }

    getMessages()

    return {
      displayedMessages,
      getMessageTypeTitle,
      messagesTypes,
      showTitles,

      icons: {
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>

<style scoped>
.invitation-container {
  width: 100%;
  overflow: hidden;
}

.message-container {
  display: flex;
  flex-direction: column;
}

.message-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  transition: all 0.5s ease;
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.message-bubble {
  background-color: #9155fd;
  color: white;
  border-radius: 15px;
  padding: 10px;
  max-width: 90%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message-title {
  font-weight: bold;
  font-size: 15px;
}

.message-text {
  margin: 0;
  font-size: 0.9rem;
}
</style>
