import { ref, watch } from '@vue/composition-api'

export function useTourGuideTitleAnimation(props, activateOtherAnimation = null, deactivateOtherAnimation = null) {
    const showTitles = ref(false)

    const activateAnimation = () => {
        setTimeout(() => {
            showTitles.value = true
            if (activateOtherAnimation) {
                activateOtherAnimation()
            }
        }, 2)
    }

    watch(
        () => props.currentStep,
        newStep => {
            if (newStep === props.stepIndex) {
                activateAnimation()
            } else {
                if (deactivateOtherAnimation) {
                    deactivateOtherAnimation()
                }
                showTitles.value = false
            }
        }, { immediate: true },
    )

    return {
        showTitles,
        activateAnimation,
    }
}